.export-container{
    height: calc(100vh - 40px);
}

.export_title{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.export_tab{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

.button_tab{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.button_tab.select{
    background: #DADADA;
}

.button_tab.unselect{
    background: transparent;
}

.button_tab label{
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button_tab.select label{
    font-weight: bold;
}