.exportPublish {
  margin: 10px;
  height: calc(100vh - 200px);
}

.publish_middle {
  height: calc(100% - 45px);
  padding: 5px 0;
  max-height: calc(100% - 115px);
  overflow-y: scroll;

  background-color: #f0f0f0;
}

.horizental_align {
  display: flex;
  align-items: center;
}

.select_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
}

.select_container > label {
  width: 140px;
}

.select_container > div {
  width: 100%;
}

.ant-tree-treenode:hover {
  background-color: #f5f5f5;
}

.ant-tree-treenode:nth-child(even) {
  background-color: #e2e2e2;
}

.ant-tree-treenode:nth-child(even):hover {
  background-color: #f5f5f5;
}

.no-publish-notion {
  display: flex;
  justify-content: center;
}

.no-publish-notion > label {
  margin: 30px 0;
  text-align: center;
  width: 180px;
  font-size: 11px;
  font-weight: 400;
  color: #b3b3b3;
}

hr.solid {
  border-top: 1px solid #dadada;
  margin: 0 -20px;
}

.rtl {
  display: flex;
  justify-content: flex-end;
}

.publish_set_folder {
  display: flex;
  justify-content: space-between;
}

.hide {
  display: none;
}

.error-item {
  font-family: "Open Sans";
  font-size: 10px;
  line-height: 14px;
  margin: 10px 0;
}
