.model_container{
    display: grid;
    grid-template-columns: auto;
    height: calc(100vh - 80px);
    grid-template-rows: 60px 50px 1px auto;
    background-color: transparent;
}

.model_title{
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.model_kind{
    grid-row: 2;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}

.model_seperate{
    grid-row: 3;
    width: 100%;
    height: 100%;
    background: #DADADA;
}

.model_content{
    grid-row: 4;
}

.page_tab.unselect{
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.page_tab.select{
    width: 100%;
    height: 100%;
    background: #DADADA;
    display: flex;
    align-items: center;
    padding: 0 5px;
}