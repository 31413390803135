.exportModel_table {
    max-height: calc(100vh - 600px);
    margin: 0 15px;
    overflow-x: hidden;
    overflow-y: auto;
}

.setting-panel {
    padding: 15px;
    border-bottom: 1px solid #EAEAEA;
}

.metadata-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.metadata-item {
    width: 48%;
}

.exportModel_settingFile{
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.exportModel_FilePath{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px;
}