.collapse_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
}

.collapse_content.collapsed{
    display: none;
}

.collapse_content.expanded{
    display: block;
}

.metaCollapse-header{
    display: grid;
    grid-template-columns: auto 13px;
}

.metaCollapse{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0 15px 0 0;
}