.editor_container{
    display: grid;
    grid-template-rows: 60px auto 50px;
    height: calc(100vh - 60px);
    background-color: transparent;
}

.editor_header{
    grid-row: 1;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.editor_footer{
    grid-row: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.editor_footer button{
    width: 100%;
    margin: 5px;
}

.editor_body{
    grid-row: 2;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    margin: 0 10px;
    border: 1px solid #666666;
}

.editor_body input{
    padding: 5px;
}

.editor_body label{
    margin: 5px 0;
}

.editor_body button{
    min-height: 30px;
    width: 100%;
    margin: 10px 0 5px 0;
}

.editor_body textarea{
    min-height: 80px;
}

.editor_elements{
    white-space: nowrap;
    max-height: 100vh;
    min-height: 50px;
    overflow-x: hidden;
    overflow-y: auto;
}

.editor_elements div{
    overflow: hidden;
    text-overflow: ellipsis;
}
