.importItem{
    margin: 10px;
}

.importItem_header{
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
}

.importItem_body{
    display: flex;
    align-items: center;
}

.importItem_body > div:first-child{
    flex: 1;
}

.importItem_body > div:last-child{
    width: 50px;
    text-align: center;
}

.importItem_body .part_1 {
    display: flex;
    align-items: center;
}

.importItem_body .part_1 > * {
    flex: 0.25;
    margin: 2px;
}