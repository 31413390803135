body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-color: transparent;
  border-radius: 20px;
}

body::-webkit-scrollbar-corner {
  background-color: transparent;
}

.visoplan_button {
  width: 90px;
  height: 30px;
  background-color: white;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: #666666;
  cursor: pointer;
}

.visoplan_button:hover {
  background-color: #f2f2f2;
}

.visoplan_button_disable {
  width: 90px;
  height: 30px;
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  color: #e5e5e5;
  cursor: pointer;
}

.visoplan_input {
  width: 100%;
  padding: 6px 10px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

.image_button {
  display: flex;
  text-align: right;
  align-items: center;
}

.transparent_button {
  border: none;
  background-color: transparent;
}

.transparent_button:hover {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

.circle {
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 5px;
}

.visoplan_Actbutton {
  width: 90px;
  height: 30px;
  background-color: #4b6ffc;
  border: 1px solid #4b6ffc;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.visoplan_Actbutton:hover {
  background-color: #607ffc;
}

button:focus {
  outline: none;
}

.visoplan_2ndButton {
  width: 90px;
  height: 30px;
  background-color: #e7eeff;
  border: 1px solid #e7eeff;
  border-radius: 4px;
  color: #4b6ffc;
  cursor: pointer;
}

.visoplan_2ndButton:hover {
  background-color: #607ffc;
  color: white;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-color: transparent;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.subtitle {
  display: flex;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 5px;
  color: #434b59;
}

.subtitle--small {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-4 {
  margin-top: 0.8rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
