.manager_container {
  display: grid;
  grid-template-rows: 40px auto;
  padding: 0 10px;
  height: calc(100vh - 25px);
  background-color: #f0f0f0;
}

.manager_header {
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}

.manager_page {
  grid-row: 2;
}
