.issue-history {
  background-color: #E7EEFF;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 1rem;
  line-height: 1.5;
  word-break: break-all;

  &--title {
    margin-bottom: 5px;
    display: flex;
    font-size: 12px;
  }

  &--creation-date {
    margin-left: auto;
  }

  &--row {
    font-size: 10px;
    &--title {
      display: flex;

      .btn-toggle-issue-log {
        margin-left: auto;
        text-decoration: underline;

        &--hide {
          display: none;
        }
      }
    }

    &.opened {
      .issue-history--row--details {
        display: block;
      }
      .btn-toggle-issue-log--hide {
        display: block;
      }
      .btn-toggle-issue-log--show {
        display: none;
      }
    }

    &--details {
      display: none;

      &--row {
        margin-top: 1rem;
        display: flex;

        strong {
          min-width: 40px;
        }
      }
    }
  }
}
