.login_center {
  background-color: #f0f0f0;
  width: 100%;
  height: calc(100vh - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_row {
  display: flex;
  flex-wrap: nowrap;
}

.login_column {
  width: 50%;
  padding: 40px 10px;
}

.login_full_column {
  width: 100%;
  padding: 0 10px 40px 10px;
}

.login_button {
  text-align: right;
  padding-right: 10px;
}

.login_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font: normal 14px "Avenir" sans-serif;
}
