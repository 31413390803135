.issueList_container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 60px 30px 40px auto auto;
    height: calc(100vh - 70px);
    background-color: transparent;
    padding: 0 10px;
}

.issueList_header{
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.rightSide{
    display: flex;
    text-align: right;
}

.issueList_blank{
    grid-row: 3;
    margin: 5px 0;
}

.issueList_buttonBar{
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.issueList_body{
    grid-row: 4;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.issueList-pagination{
    grid-row: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}
