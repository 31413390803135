.importModel_container{
    display: grid;
    height: calc(100vh - 175px);
    grid-template-rows: auto 50px;
    background-color: transparent;
}

.importModel_table{
    grid-row: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.importModel_table td{
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.importModel_open{
    grid-row: 2;
    padding-top: 10px;
    text-align: right;
}