.expandableText{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &-content{
    white-space: pre-line;
    
    &_more{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  &-labelMore{
    cursor: pointer;
    color: #4B6FFC;
    text-decoration: underline;
  }
}