.item_body {
    width: 99%;
    border: 1px solid #D4D4D4;
    border-radius: 2px;
    margin: 0 0 3px;
    cursor: pointer;
}

.item_body:hover {
    border: 2px solid #486AFF;
    border-radius: 2px;
}

.item_body_content {
    padding: 10px;
}

.flex_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ellipsis {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item_body_bottom {
    background: #F6F9FF;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}