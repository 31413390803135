.export-other{
    margin: 0 15px;
}

.export-content{
    display: flex;
    margin: 10px 0;
}

.export-content > div{
    flex: 1;
    margin: 5px 5px 5px 0;
}

.export-other label{
    font-weight: bold;
}

.exportOther-table{
    margin: 10px 5px;
    height: calc(100vh - 550px);
    overflow-x: hidden;
    overflow-y: auto;
}
