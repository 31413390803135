.viso_image{
    width: 120px;
    height: 90px;
}

.viso_image.viso_image.selectable{
    border:1px solid #E3E3E3;
    cursor: pointer;
}

.viso_image.selectable:hover{
    border:2px solid #486AFF;
}

.viso_image_container{
    display: flex;
    width: 145px;
}