.svg-icon {
  width: 1em;
  height: 1em;

  path,
  polygon,
  rect {
    fill: #4691f6;
  }

  circle {
    stroke: #4691f6;
    stroke-width: 1;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900,300,100);

html {
  font-family: Roboto;
}

.datePicker {
  position: relative;
  &Label,
  &Selector {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
  }

  &Selector {
    padding: 0;
    overflow: hidden;
    display: none;

    &.active {
      position: absolute;
      display: block;
      z-index: 1 !important;
      background-color: white;
    }

    &Table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid #eee;
        &:last-child {
          border-bottom: 0;
        }
      }

      td {
        padding: 5px;
        border-right: 1px solid #eee;
        &:last-child {
          border-right: 0;
        }
      }

      &Header {
        &CurrentMonth {
          text-align: center;
        }

        &PreviousMonth,
        &NextMonth {
          display: block;
          margin: auto;
          path {
            fill: black;
          }
          &:hover path {
            fill: #4691f6;
          }
          cursor: pointer;
        }
      }

      &Days {
        &:hover {
          background: lighten(#e3effe, 4%);
        }

        &,
        &NotInMonth {
          cursor: pointer;
        }

        &,
        &Header,
        &NotInMonth,
        &Selected {
          text-align: center;
        }

        &Header {
          font-weight: bold;
        }

        &NotInMonth {
          color: #ddd;

          &:hover {
            background: #fdffdd;
          }
        }

        &Selected {
          background: #e3effe;
          border-left: 0;
          cursor: default;
        }
      }
    }
  }

  &Caret {
    transform: rotate(90deg);
    margin-top: 0.18em;
    float: right;

    path {
      fill: #444;
    }
  }

  &Label:hover {
    border-color: #4691f6;

    .datePicker {
      &Caret path {
        fill: #4691f6;
      }
    }
  }

  &Label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &_closeIcon {
      margin: 0 5px;
      color: #d3d3d3;
    }

    &_closeIcon:hover {
      color: #666666;
    }
  }
}
