.project_container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 60px 30px auto 50px;
  height: calc(100vh - 25px);
  background-color: #f0f0f0;
  padding: 0 10px;
}

.project_header {
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}

.project_title {
  grid-row: 2;
}

.project_table {
  grid-row: 3;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.striped_row {
  background-color: white;
}

.striped_row:nth-child(even) {
  background-color: #e2e2e2;
}

.striped_row:hover {
  border: 1px solid #18a0fb;
}

.striped_row_selected {
  background-color: #4b6ffc;
  color: white;
}
.no_border:hover {
  border: none;
}

.project_open {
  grid-row: 4;
  padding-top: 10px;
  text-align: right;
}
