.detail_container {
    display: grid;
    grid-template-rows: 60px 30px 20px auto;
    height: calc(100vh - 60px);
    background-color: transparent;
    padding: 0 10px;
}

.detail_header {
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.detail_back {
    grid-row: 2;
    display: flex;
    justify-content: left;
    align-items: baseline;
}

.detail_blank {
    grid-row: 3;
}

.detail_body {
    grid-row: 4;
    background-color: white;
    border: 1px solid #666666;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 10px 0;
    display: grid;
    grid-template-rows: 75px auto 40px;
}

.detail_body_header {
    grid-row: 1;
    background-color: #F3F3F3;
    padding: 0 10px;
}

.detail_body_footer {
    grid-row: 3;
    background-color: #F3F3F3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.send_comment {
    width: 100%;
    height: 20px;
    margin: 0 10px 0 0;
}

.detail_body_content {
    grid-row: 2;
    padding: 0 10px;
}

.content_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content_title_header {
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 5px;
}

.splitter {
    background-color: #666666;
    width: 100%;
    height: 1px;
}

.content_reviewer {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

/***************** Comment Style *********************/
.comment_list {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.comment_body {
    background-color: #F3F3F3;
    width: 100%;
    margin: 0 0 5px 0;
}

.comment_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

/***************** Viewpoint Style*********************/
.viewpoint_list {
    display: flex;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 5px 0;
    flex-wrap: wrap;
}

.viewpoint_list img {
    margin: 5px;
}

/***************** Element Style*********************/
.element_list {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 5px 0;
}

.element_list div {
    padding: 0 0 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.workPhase {
    display: flex;
    color: #F79646;
    background: #F8E3D4;
    height: 24px;
    border-radius: 12px;
    padding: 0 10px;
    text-align: center;
    align-items: center;
}