.filter_container {
  display: grid;
  grid-template-rows: 60px 30px 20px auto 40px;
  height: calc(100vh - 60px);
  background-color: transparent;
  padding: 0 10px;
}

.filter_header {
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.filter_back {
  grid-row: 2;
  display: flex;
  justify-content: left;
  align-items: baseline;
}

.filter_label {
  grid-row: 3;
  font-weight: 500;
}

.filter_body {
  grid-row: 4;
  background-color: white;
  border: 1px solid #666666;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: auto;
  margin: 10px 0;
  padding: 10px;
}

.filter_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 10px;
}

.filter_grid label {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 5px;
  padding: 2px 6px;
  color: #434b59;
}

.filter_group {
  margin-top: 15px;
}
